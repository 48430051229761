export function loadRemoteCss(link: string, ...files: string[]) {
  const elements: HTMLElement[] = [];

  return {
    addToDocumentHead() {
      for (const file of files) {
        const link_el = document.createElement("link");
        link_el.rel = "stylesheet";
        link_el.href = link + file;
        document.head.appendChild(link_el);
        elements.push(link_el);
      }
    },
    removeFromDocumentHead() {
      for (const link_el of elements) {
        document.head.removeChild(link_el);
      }
    },
    async getStyleComponents() {
      return new Promise(async (res: (props: HTMLStyleElement[]) => void, rej) => {
        const els: HTMLStyleElement[] = [];
        for (const file of files) {
          await fetch(link + file)
            .then((response) => {
              if (!response.ok) {
                throw "css style for file :: " + file + " :: failed to load!";
              }
              return response.text();
            })
            .then((style) => {
              console.log("style for ", file, " :: is :: ", style.matchAll(/^(width|height)[^;]+(px|em|rem|%)/g));

              const style_el = document.createElement("style");
              style_el.innerHTML = style;
              els.push(style_el);
            })
            .catch((e) => {
              console.error("error getStyleComponents :: ", e);
            });
        }
        res(els);
      });
    },
    async appendStyleNodes(ref: HTMLElement) {
      const style_els = await this.getStyleComponents();
      for (const style_el of style_els) {
        ref.appendChild(style_el);
      }
    },
  };
}
